<template>
    <div>
        <div class="list">
            <ul>
                <li @click="goinfo('cardInfo')">
                    <img src="../../assets/img/ambulance.png" alt="">
                    <p class="name">白金会员卡</p>
                    <p class="number">4569 5658 4548 454</p>
                    <p class="time">2020.12.10-2021.2.10</p>
                    <p class="price">免费</p>
                </li>
                <li>
                    <img src="../../assets/img/ambulance.png" alt="">
                    <p class="name">白金会员卡</p>
                    <p class="number">4569 5658 4548 454</p>
                    <p class="time">2020.12.10-2021.2.10</p>
                    <p class="price">免费</p>
                </li>
                <li>
                    <img src="../../assets/img/ambulance.png" alt="">
                    <p class="name">白金会员卡</p>
                    <p class="number">4569 5658 4548 454</p>
                    <p class="time">2020.12.10-2021.2.10</p>
                    <p class="price">免费</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name:'cardInfo',
    methods:{
        goinfo(names){
            this.$router.push({name: names})
        },
    }
}
</script>

<style lang="less" scoped>
.list { 
    li {
        width: 100%; height: 190px; background: url('../../assets/img/card.png') no-repeat center center; position: relative; background-size: 100%; 
        img { width: 52px; height: 20px; display: block; float: left; position: absolute; top: 20px; left: 32px;} 
        .name { font-style:italic; color: #E2F5FF; font-size: 14px; font-weight: bold; line-height: 20px; position: absolute; left: 100px; top: 20px} 
        .number { text-align: center; font-size: 24px; color: #FFFFFF; line-height: 30px; padding-top: 80px}
        .time { font-size: 12px; color: #FFFFFF; position: absolute; top: 140px; right: 48px;}  
        .price { background: linear-gradient(129deg,#ffe4bc 15%, #c18241); border-radius: 15px 0 0 15px; width: 76px; height: 30px; font-size: 14px; color: #FFFFFF; font-weight: blod; line-height: 30px; text-align: center; right: 16px; top: 30px; position: absolute}
    }
}
</style>
